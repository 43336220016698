import { getOrCreateProcedure } from 'o365.vue.ts';

export class DataLookupControl {

    lastWhereClause;
    procRecent;


    constructor(pOptions) {        
        if (!(pOptions.target instanceof HTMLElement)) {
            throw new Error('Target has to be an instance of {HTMLElement}');
        }
        this.target = pOptions.target;
        this.dataObject = pOptions.dataObject;

        if (pOptions.display) {
            this.display = pOptions.display;
        }

        if (pOptions.whereClause) {
            this.whereClause = pOptions.whereClause;
        }

        if (typeof pOptions.bind === 'function') {
            this.bind = pOptions.bind;
        }

        this.bindRef = pOptions.bindRef;

        this.lookupColumns = pOptions.lookupColumns;

        this.open = pOptions.open;
        this.close = pOptions.close;

        if (!pOptions.suppressClickEvent) {
            this.target.addEventListener('click', (e) => {
                this.open();
            });
        }

        if (pOptions.openOnFocus) {
            this.target.addEventListener('focus', (e) => {
                this.open();
            });
        }

        this.procRecent = getOrCreateProcedure({
            id: 'procAddBookmark',
            procedureName: 'sstp_System_LookupSelections'
        });
    }

    #setDisplayValue(value) {
        switch (this.target.tagName) {
            case 'INPUT':
                this.target.value = value;
                this.target.dispatchEvent(new Event('input'))
                break;
            default:
                this.target.innerText = value;
                this.target.dispatchEvent(new Event('change'))
        }
    }


    select(pItem) {
        const item = pItem?.item ?? pItem;
        if (this.bindRef.value) { this.bindRef.value(item); }
        else if (this.display) { this.#setDisplayValue(item[this.display]); }
        this.updateLkpSelection(item);
        this.close();
    }

    updateLkpSelection(item){
        if(this.dataObject && item.ID && typeof item.ID === 'number'){
            this.procRecent.execute({
                Record_ID: item.ID,
                ViewName: this.dataObject.viewName,
                Pinned:item._pinned
            });
        }
    }

    multiSelect(pItems) {
        if (this.bind) { this.bind(pItems); }

        this.close();
    }
    
    pinnedClick(item,ev){
        if(item._pinned === null) item._pinned = false;
        item._pinned = !item._pinned;
        this.updateLkpSelection(item);

        if(item._pinned){
            //move to top to top
            if(item.index > 0) this.dataObject.reshiftItem(item.index,0);
        }else{
            const vPinned = this.dataObject.data.filter(x=>x._pinned);
            if(vPinned.length > 0) this.dataObject.reshiftItem(item.index,vPinned[vPinned.length - 1].index);
            
            
        }
       // if(this.dataObject) this.dataObject.load();
        //do ds magic, bring in front
        ev.preventDefault();
        ev.stopPropagation();
        ev.stopImmediatePropagation();
        
    }

    clearSelection(bindClear) {
        this.#setDisplayValue(null);
        if (typeof bindClear === 'function') {
            bindClear();
        } else if (this.bindRef.value) {
            const emptyRow = {};
            this.lookupColumns.value.forEach(col => {
                emptyRow[col.field] = null;
            });
            this.bindRef.value(emptyRow);
        }
        this.close();
    }

    handleOpen() {
        if (!this.dataObject) { console.warn('DataLookupControl: No dataobject given'); return; }
        const vDO = this.dataObject;
        if (vDO) {
            vDO.recordSource.loadIfWhereClauseChanged();
            /*
            this.lastWhereClause = this.whereClause;
            if (!this.lastWhereClause) { this.lastWhereClause = vDO.recordSource.whereClause; }
            const vDOState = vDO.stateStorage ?? vDO.state;
            if (!vDOState._isLoaded || vDO.recordSource.whereClause !== this.lastWhereClause) {
                if (vDO.recordSource.whereClause && this.whereClause) {
                    this.lastWhereClause = `(${vDO.recordSource.whereClause}) AND (${this.lastWhereClause})`;
                }
                // vDO.recordSource.whereClause = this.lastWhereClause
                vDO.load({
                    // whereClause: this.lastWhereClause
                });
            }
            */
        }
    }
}

//o365.controls.DataLookupControl = DataLookupControl;

